import { useEffect, useState } from "react";

function App() {
  const [category, setCategory] = useState("Programming");

  function handleCategory(newCategory) {
    setCategory(newCategory);
  }
  return (
    <div
      className=" pt-2 
    container  border-top  border-5 border-primary "
    >
      <header className="text-center mt-5 display-1 text-decoration-underline">
        <h1>
          <span className="font-monospace"> Joke Generator.</span>
        </h1>
      </header>

      <Joke category={category} handleCategory={handleCategory} />
    </div>
  );
}

function Joke({ category, handleCategory }) {
  const [joke, setJoke] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const APILink = `https://v2.jokeapi.dev/joke/${category}?type=single`;

  function handleClick() {
    setIsClicked((l) => (l === isClicked ? !isClicked : isClicked));
  }

  useEffect(
    function handleJokeGenerator() {
      async function apiJoke() {
        const res = await fetch(APILink);
        const data = await res.json();
        console.log(data);
        setJoke(data.joke);
      }
      apiJoke();
    },
    [isClicked, APILink]
  );

  return (
    <div className="text-center">
      <p className="text-center mt-5 h5">{joke}</p>
      <button
        type="button"
        className="btn  btn-primary mt-3 "
        onClick={handleClick}
      >
        New {`${category}`} Joke!
      </button>
      <div className="flex-column">
        <div className="mt-4">
          <p className="text-decoration-underline lead ">Category:</p>
        </div>
        <div className="d-flex justify-content-center ">
          <button
            className={
              category === "Programming"
                ? "btn btn-dark btn-sm mx-1"
                : "btn btn-outline-dark btn-sm mx-1"
            }
            onClick={() => handleCategory("Programming")}
          >
            Programming
          </button>
          <button
            className={
              category === "Misc"
                ? "btn btn-dark btn-sm mx-1"
                : "btn btn-outline-dark btn-sm mx-1"
            }
            onClick={() => handleCategory("Misc")}
          >
            Misc
          </button>
          <button
            className={
              category === "Dark"
                ? "btn btn-dark  btn-sm mx-1"
                : "btn btn-outline-dark  btn-sm mx-1"
            }
            onClick={() => handleCategory("Dark")}
          >
            Dark
          </button>
          <button
            className={
              category === "Pun"
                ? "btn btn-dark  btn-sm mx-1"
                : "btn btn-outline-dark btn-sm mx-1"
            }
            onClick={() => handleCategory("Pun")}
          >
            Pun
          </button>
        </div>
      </div>
    </div>
  );
}
export default App;
